<template>
  <div class="nav_placeholder">
    <nav id="navbar" class="flex_row flex_center autohide" :class="this.lock">
      <div class="container">
        <div class="flex_row flex_space">
          <router-link to="/">
            <WLabsLogo class="logo" :class="this.logoDisplay" />
          </router-link>

          <div class="menu-burger above-overlay" @click="triggerMenu">
            <div class="bar" :class="this.menuState"></div>
          </div>
        </div>
      </div>
    </nav>

    <transition name="fade" v-on:enter="enter">
      <div v-if="this.menuState === 'animate'" class="overlay flex_col flex_center">
        <div class="container">
          <ul class="menu-options-list alt" :class="this.fadeDownIn">
            <!-- <li class="menu-option option-2">
              <router-link to="/services/" @click="triggerMenu">Services</router-link> 
            </li> -->

            <li class="menu-option option-1">
              <router-link to="/" @click="triggerMenu">What we do</router-link> 
            </li>

            <!-- <li class="menu-option option-2">
              <router-link to="/team/" @click="triggerMenu">The team</router-link>
            </li> -->
            
            <li class="menu-option option-2">
              <router-link to="/ourecosystem/" @click="triggerMenu">Our Ecosystem</router-link>
            </li>

            <li class="menu-option option-3">
              <router-link to="/cases/" @click="triggerMenu">Case Studies</router-link> 
            </li>
            
            <li class="menu-option option-5">
              <router-link to="/contact/" @click="triggerMenu">Contact</router-link> 
            </li>
          </ul>

          <div class="nav_footer flex_row flex_space">
            <div class="flex_row fit_content">
              <router-link to="/careers/" @click="triggerMenu">careers</router-link>
              <a href="https://www.linkedin.com/company/wlabsgg/" target="_blank">LinkedIn</a>
            </div>

            <p class="credentials">© 2022 W Labs • 752 North Bridge Rd, Singapore 198720</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <router-view/>

  <footer class="flex_col flex_center">
    <div class="container">
      <div class="flex_col">
        <h2 class="alt" v-motion-slide-visible-left :delay="200">Let's work<br>together</h2>

        <div class="flex_row resp resp_center" v-motion-slide-visible-bottom :delay="200">
          <a class="mailto popup_container" href="mailto:contact@wlabs.gg">
            <p class="contact_button" @click="triggerEmail()">contact@wlabs.gg</p>
            <p :class="this.contact_state" class="popup_copy">Email address copied!</p>
          </a>

          <a href="https://www.linkedin.com/company/wlabsgg/" target="_blank" class="contact_button flex_row flex_center">
            <i class="fa-brands fa-linkedin"></i>
            <p>Connect on LinkedIn</p>
          </a>
        </div>

        <div class="flex_row details resp">
          <div class="flex_row fit_content resp footer_row">
            <div class="flew_col resp_none">
              <p class="title">Local Time</p>
              <p class="time">{{this.time}}</p>
            </div>

            <div class="flew_col marg resp rest_center">
              <p class="title resp_none">Legal</p>
              
              <div class="flex_row resp">    
                <router-link to="/privacy/">Privacy Policy</router-link>
                <router-link to="/terms/">Terms & conditions</router-link>
              </div>
            </div>
          </div>

          <p class="copyright footer_row">© 2022 W Labs • 752 North Bridge Rd, Singapore 198720</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import WLabsLogo from './components/WLabsLogo.vue'

export default {
  components: {
    WLabsLogo,
  },
  data() {
    return {
      menuState: "",      
      fadeDownIn: "",
      logoDisplay: "",
      time: "",
      contact_state: "",
      lock: ""
    };
  },
  created() {
    var date = new Date().toLocaleString('en-SG', {
      timeZone: 'Asia/Singapore',
      hour12: true
    })

    date = date.split(' ')
    var getTime = date[1].split(':')

    this.time = getTime[0]+":"+getTime[1]+" "+date[2] +" UTC+8";

    // document.addEventListener("DOMContentLoaded", function(){
      
    //   var el_autohide = document.querySelector('.autohide');

    //   var navbar_height = document.querySelector('#navbar').offsetHeight;
    //   document.body.style.paddingTop = navbar_height + 'px';

    //   if(el_autohide){
    //     var last_scroll_top = 0;
    //     window.addEventListener('scroll', function() {
    //           let scroll_top = window.scrollY;
              
    //           if(scroll_top < last_scroll_top) {
    //               el_autohide.classList.remove('scrolled-down');
    //               el_autohide.classList.add('scrolled-up');
                  
    //           } else {
    //                 el_autohide.classList.remove('scrolled-up');
    //                 el_autohide.classList.add('scrolled-down');
    //           }

    //           last_scroll_top = scroll_top;
    //     }); 
    //   }
    // }); 
  },
  methods: {
    async triggerMenu(){      
      if(this.menuState === ""){
        this.menuState = "animate"
        this.logoDisplay = "nav_active"
        this.fadeDownIn = "slideDownIn"
        this.lock = "lock"
        
      } else {
        this.menuState = ""
        this.logoDisplay = ""
        this.lock = ""
      }
    },
    async triggerEmail(){
      navigator.clipboard.writeText("contact@wlabs.gg");
      this.contact_state = "active";

      setTimeout(() => {
        this.contact_state = ""
      }, 5000) 

    }
  },
}
</script>

<style lang="scss">

@import "styles/helpers/_reset.scss";
@import "styles/helpers/_typo.scss";
@import "styles/helpers/_util.scss";
@import "styles/helpers/_variables.scss";
@import "styles/helpers/_transition.scss";
@import "styles/components/_nav.scss";
@import "styles/components/_burger.scss";
@import "styles/components/_footer.scss";

</style>