import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/team/',
  //   name: 'team',
  //   component: () => import('../views/TheTeamView.vue')
  // },
  {
    path: '/ourecosystem/',
    name: 'ourecosystem',
    component: () => import('../views/OurEcosystemView.vue')
  },
  {
    path: '/services/',
    name: 'services',
    component: () => import('../views/ServicesView.vue')
  },
  {
    path: '/contact/',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/cases/',
    name: 'cases',
    component: () => import('../views/CaseStudiesView.vue')
  },
  {
    path: '/careers/',
    name: 'careers',
    component: () => import('../views/CareersView.vue')
  },
  {
    path: '/privacy/',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/terms/',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
]

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      }    
    } else {
      return {
        top: 0
      }
    }
  },
  
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
