<template>
  <div class="home">
    <header class="flex_col flex_center">
      <div class="container flex_row flex_space resp">
        <div class="hero">
          <h1>Your Brand's<br>Digital Voice</h1>
          <div class="text">
            <span class="adj"></span>
            <span class="cursor">_</span>
          </div>
        </div>
        
        <div class="banner">
          <!-- <img src="" alt=""> -->
        </div>
      </div>
    </header>

    <section id="about">
      <div class="container">
        <div class="intro flex_row resp">
          <h3 v-motion-slide-visible-once-left :delay="200">A digital marketing agency specialized in the gaming and esports industries. Our team is dedicated to help our clients succeed in these rapidly growing markets. </h3>
          <!-- <p  v-motion-slide-visible-once-right :delay="400">We offer a wide range of services to help our clients reach their target audience and achieve their marketing goals.</p> -->
        </div>
      </div>

      <div>
        <div id="map" class="flex_col flex_center resp_none" @scroll="counterUp" v-motion-slide-visible-once-bottom :delay="200">
          <img class="map_placeholder" src="../assets/img/map.svg" alt="World map">
        </div>

        <div class="container" v-motion-slide-visible-once-bottom :delay="200">
          <div id="numbers" class="flex_row flex_center highlight_global_numbers resp">
            <div class="flex_row flex_center resp">
              <p id="count1">+60</p>
              <p>Media<br>Websites</p>
            </div>

            <div class="line"></div>

            <div class="flex_row flex_center resp">
              <p id="count2">36</p>
              <p>Covered<br>Countries</p>
            </div>

            <div class="line"></div>

            <div class="flex_row flex_center resp">
              <p id="count3">26</p>
              <p>Covered<br>Languages</p>
            </div>
          </div> 
        </div>
      </div>
    </section>

    <section id="esports">
      <div class="container">
        <div class="left_panel">
          <h2 class="alt" v-motion-slide-visible-once-left :delay="200"><span>Esports</span> at<br>it's best</h2>
          <p v-motion-slide-visible-once-left :delay="200">With more than 15 years working in the Esports industry, we are uniquely positioned to help brands & rights holders to develop and realize their global Esports ambitions. Working with stakeholders within the ecosystem on various Esports initiatives, we advise and create strategic plans to help them achieve their short & long-term objectives.</p>
        </div>          
      </div>

      <div class="container" v-motion-slide-visible-once-bottom :delay="200">
        <div class="esports_services">
          <p class="alt headline" @mouseover="esports1()">Team<br>Management</p>

          <div class="line"></div>
          
          <p class="alt headline" @mouseover="esports2()">Strategic<br>Consultancy </p>

          <div class="line"></div>

          <p class="alt headline" @mouseover="esports3()">Commercial and<br>Sponsorship</p>

          <div class="line"></div>

          <p class="alt headline" @mouseover="esports4()">Global<br>Networking</p>

          <transition name="fade" v-on:enter="enter">
            <div v-if="this.es1 === true" class="abstract_object">
              <img src="../assets/img/esports-1.png" alt="Esports - Team Management">
            </div>
          </transition>

          <transition name="fade" v-on:enter="enter">
            <div v-if="this.es2 === true" class="abstract_object">
              <img src="../assets/img/esports-2.png" alt="Esports - Strategy">
            </div>
          </transition>

          <transition name="fade" v-on:enter="enter">
            <div v-if="this.es3 === true" class="abstract_object">
              <img src="../assets/img/esports-3.png" alt="Esports - Sponsoring">
            </div>
          </transition>

          <transition name="fade" v-on:enter="enter">
            <div v-if="this.es4 === true" class="abstract_object">
              <img src="../assets/img/esports-4.png" alt="Esports - Networking">
            </div>
          </transition>
        </div>
      </div>
    </section>

    <section id="marketing">
      <div class="container">
        <h2 class="alt">Digital<br>marketing</h2>
        <h3 class="alt">Is part of our DNA</h3>
        
        <div class="grid">
          <div class="service" v-motion-slide-visible-once-left :delay="200">
            <img class="" src="../assets/img/services/home/noorstars.jpg" alt="Talent Activation Illustration">
            <h4 class="alt">Talent<br>Activation</h4>
          </div>  
          
          <div class="service" v-motion-slide-visible-once-right :delay="200">
            <img src="../assets/img/services/home/media.jpg" alt="Media Solution Illustration">
            <h4 class="alt">Media<br>Solution</h4>
          </div>

          <div class="service" v-motion-slide-visible-once-left :delay="200">
            <img src="../assets/img/services/home/szabo-viktor-tsbcvd1YBZo-unsplash.jpg" alt="Community Management Illustration">
            <h4 class="alt">Community<br>Management</h4>
          </div>
          
          <div class="service" v-motion-slide-visible-once-right :delay="200">
            <img class="right" src="../assets/img/services/home/41182895915_ba7eb37649_c.jpg" alt="Video Production Illustration">
            <h4 class="alt">Content<br>Production</h4>
          </div>
        </div>
      </div>
    </section>

    <section id="cases">
      <div class="container" v-motion-slide-visible-once-bottom :delay="200">
        <div class="flex_row flex_space cases_title resp">
          <h2 class="alt">case studies</h2>
          <router-link to="/cases/" class="resp_none">
            <p class="cta alt_cta flex_row flex_center">More case studies</p>
          </router-link>
        </div>
        
        <div class="flex_row flex_space cases_box resp">
          <router-link to="/cases/" class="box">
              <div class="box_relative">
                <img class="banner" src="../assets/img/cases/studies_lotr.jpg" alt="The Lord of the Rings: Rise to War - banner">
                <img class="logo" src="../assets/img/cases/studies_lotr_logo.png" alt="The Lord of the Rings: Rise to War - logo">
              </div>

              <h3>Media Campaign</h3>
              <h4>The Lords of the Rings: Rise to War Launch Campaign</h4>
          </router-link>
          
          
          <router-link to="/cases/" class="box">
            <div class="box_relative">
              <img class="banner" src="../assets/img/cases/studies_idtv.jpg" alt="Identity V - banner">
              <img class="logo" src="../assets/img/cases/studies_idtv_logo.png" alt="Identity V - logo">
            </div>

            <h3>Community Management</h3>
            <h4>NetEase Identity V Social Media Management</h4>
          </router-link>

          <router-link to="/cases/" class="box">
            <div class="box_relative">
              <img class="banner" src="../assets/img/cases/studies_psg.jpg" alt="PSG Esports - banner">
              <img class="logo" src="../assets/img/cases/studies_psg_logo.png" alt="PSG Esports - logo">
            </div>

            <h3>Esports Consultancy</h3>
            <h4>PSG Esports team management & global strategy</h4>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data() {
    return {
      es1: true,  
      es2: false,  
      es3: false,  
      es4: false,  
      scroll_h: null,
    };
  },
  created(){
    document.title =  "Home | W Labs.gg";

    // Object DOM
    const dom = {
      text: '.text',
      cursor: '.cursor',
      adj: '.adj'
    };
        
    // Initial array with strings to write
    const array1 = ['Digital Marketing', 'Esports Strategy', 'Content Production',];
    
    //Trannsform the array in a string
    const str = array1.join('');
    
    //Create a new array with each character of the array
    const array2 = str.split('');
    
    let i = 0;
    let j = 0;
    let k = 0;

    function writeEffect () {
      // set the speed of writting each character
      let speed = 90;
      
      //add each character to the html
      document.querySelector(dom.adj).innerHTML += `${array2[i]}`;

      // advance the counter of the array2.length and element of the array1.lenght
      i++;
      k++;
      
      //get the length of the each element of the array 1
      let index1 = array1[j].length - 1;

      //Test if the counter is bigger that the lenght of the element of array1
      if (k > index1) {

          //set the blink animation
          document.querySelector(dom.cursor).setAttribute('style', 'animation-play-state: initial');
          //set counter to 0 to be ready to count the length of the next element of array1 
          k = 0;

          //advance to the next element of the array1
          j++;

          //clear html
          setTimeout(eraseEffect,1500);

          //call the function writeEffect
          setTimeout(writeEffect, 3000)
              
          //if not continue to write the character
      } else {  
          setTimeout(writeEffect, speed);
          document.querySelector(dom.cursor).style.animation= 'blink 0s';
      }
      //if it's the end of the total array1 and 2, set the counters to 0 to start looping again of the arrays.
      if (i > (array2.length - 1) && (j > (array1.length - 1))) {
          i = 0;
          j = 0;
      }          
    }

    function eraseEffect () {
      let newStr =  document.querySelector(dom.adj).innerHTML;
      let set;

      if (newStr.length > 0) {
          newStr = newStr.slice(0, -1);
          document.querySelector(dom.adj).innerHTML = `${newStr}`;
          document.querySelector(dom.cursor).style.animation= 'blink 0s';
          set = setTimeout(eraseEffect, 25);
          
      } else if (newStr.length <= 0) {
          document.querySelector(dom.cursor).setAttribute('style', 'animation-play-state: initial');
          clearTimeout(set);
      }
    }

    // call write effect
    document,addEventListener('load', () => {
      writeEffect();
    });

    
  },

  methods: {
    async esports1(){      
      this.es1 = true;
      this.es2 = false;
      this.es3 = false;
      this.es4 = false;
    },

    async esports2(){      
      this.es1 = false;
      this.es2 = true;
      this.es3 = false;
      this.es4 = false;
    },

    async esports3(){      
      this.es1 = false;
      this.es2 = false;
      this.es3 = true;
      this.es4 = false;
    },

    async esports4(){      
      this.es1 = false;
      this.es2 = false;
      this.es3 = false;
      this.es4 = true;
    },
  },
}
</script>

<style lang="scss">

@import "../styles/components/_home.scss";

</style>